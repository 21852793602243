import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box, Snackbar, Typography, Grid } from '@mui/material';
import QuestionsScreen from '../components/QuestionsScreen';
import Questions from '../modules/Questions';
import Answers from '../modules/Answers';
import Logo from '../assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import University from '../modules/University';
import Skeleton from '@mui/material/Skeleton';

export default function SixMedCalculator() {
  const [questions, setQuestions] = React.useState([]);
  const [activeScreen, setActiveScreen] = React.useState(1);
  const [scoreTypes, setScoreTypes] = React.useState([]);
  const [activeQuestions, setActiveQuestions] = React.useState([]);
  const [answerSubmitList, setAnswerSubmitList] = React.useState([]);
  const [universities, setUniversities] = React.useState([]);
  const [screens, setScreens] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState({ open: false });

  let navigateTo = useNavigate();
  React.useEffect(() => {
    Questions.getAllQuestions(response => {
      if (response.status === 'success') {
        let questionsList = [...response.data];
        setQuestions(questionsList);
        let totalScreens = [
          ...new Set(questionsList.map(question => question.screenId)),
        ];
        setScreens(totalScreens);
        let activeQuestionsList = getActiveScreenQuestions(
          questionsList,
          activeScreen
        );
        setActiveQuestions(activeQuestionsList);
      }
    });
  }, []);

  const getActiveScreenQuestions = (questionsList, active) => {
    let activeQuestionsList = questionsList.filter(
      question =>
        question.screenId == active &&
        (question.questionShowUpCondition == '' ||
          answerSubmitList.findIndex(
            a => a.answerOption == question.questionShowUpCondition
          ) != -1 ||
          scoreTypes.includes(question.questionShowUpCondition))
    );
    return activeQuestionsList;
  };

  const handleNextScreen = answersList => {
    answersList.forEach(answer => {
      let answerIndex = answerSubmitList.findIndex(
        a =>
          a.questionId == answer.questionId &&
          a.answerOptionId == answer.answerOptionId
      );
      if (answerIndex == -1) {
        answerSubmitList.push(answer);
      } else {
        answerSubmitList[answerIndex] = answer;
      }
      setAnswerSubmitList(answerSubmitList);
    });
    let newActiveScreen = activeScreen + 1;
    if (newActiveScreen == 2) {
      handleUniversities();
    }
    if (newActiveScreen <= screens.length) {
      setActiveScreen(newActiveScreen);
      let activeQuestionsList = getActiveScreenQuestions(
        questions,
        newActiveScreen
      );
      setActiveQuestions(activeQuestionsList);
    }
  };

  const handleUniversities = () => {
    let universityNames = answerSubmitList
      .map(a => {
        if (a.questionId == 1) return a.answerOption;
      })
      .filter(a => a != undefined);
    if (universityNames.length > 0) {
      University.getAllUniversities(response => {
        if (response.status == 'success') {
          let universitiesList = response.data.filter(u =>
            universityNames.includes(u.universityName)
          );
          setUniversities(universitiesList);
          let universitiesScoreTypes = [
            ...new Set(universitiesList.map(uni => uni.followUCATorBMAT)),
          ];
          setScoreTypes(universitiesScoreTypes);
        }
      });
    }
  };

  const handleBackScreen = () => {
    if (activeScreen > 1) {
      let newActiveScreen = activeScreen - 1;
      setActiveScreen(newActiveScreen);
      let activeQuestionsList = getActiveScreenQuestions(
        questions,
        newActiveScreen
      );
      setActiveQuestions(activeQuestionsList);
    }
  };

  const handleAnswerSubmit = () => {
    Answers.submitAnswers(answerSubmitList, response => {
      if (response.status == 'success') {
        let result = { ...response.data };
        result.answersList = answerSubmitList;

        // navigating to results page
        navigateTo('/result', {
          state: result,
        });
      } else {
        console.log('error');
      }
    });
  };

  const handleSnackbar = value => {
    setSnackbar({ ...value });
  };

  return (
    <Box sx={{ padding: { sm: '0 100px', xs: '0 50px' } }}>
      <Box textAlign='center'>
        <img src={Logo} alt='Logo image' height='50px' />
      </Box>
      <Typography variant='h4' align='center'>
        Medical School Success Calculator
      </Typography>
      <Typography variant='body1' align='center' color='gray' mb={6}>
        Find out your statistical chance of getting into a medical school.
      </Typography>

      <Stepper sx={{ mb: 6 }} activeStep={activeScreen - 1} alternativeLabel>
        {screens.map(label => (
          <Step key={label}>
            <StepLabel>
              <></>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {snackbar.open ? (
        <Snackbar
          className={snackbar.error ? `snackbar_red` : `snackbar_green`}
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={event => setSnackbar({ open: false, message: '' })}
          message={snackbar.message}
        />
      ) : null}

      {activeQuestions.length > 0 && screens.length > 0 ? (
        <QuestionsScreen
          key={`screen_${activeScreen}`}
          activeScreen={activeScreen}
          questions={activeQuestions}
          snackbar={handleSnackbar}
          submitAnswer={activeScreen == screens.length}
          handleNextScreen={handleNextScreen}
          handleBackScreen={handleBackScreen}
          handleAnswerSubmit={handleAnswerSubmit}
          answerSubmitList={answerSubmitList}
        />
      ) : null}
    </Box>
  );
}
