import SixMedAPI from './SixMedAPI';

class Answers {
  getAnswersByQId = (qId, callback) => {
    SixMedAPI({
      method: 'GET',
      url: `/Questionnaire/GetAllAnswersOptionsByQuestionId?questionId=${qId}`,
    })
      .then(response => {
        callback({ status: 'success', data: [...response.data?.result] });
      })
      .catch(error => {
        console.log('error occurred in getAllQuestions ' + error);
        callback({ status: 'error' });
      });
  };

  submitAnswers = (answers, callback) => {
    SixMedAPI({
      method: 'POST',
      url: `/Applicant/CalculateApplicantSuccessScore`,
      data: { answers: answers },
    })
      .then(response => {
        callback({ status: 'success', data: { ...response.data?.result } });
      })
      .catch(error => {
        console.log('error occurred in getAllQuestions ' + error);
        callback({ status: 'error' });
      });
  };
}

export default new Answers();
