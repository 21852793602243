import SixMedConfig from "../config/six-med-config";

class Auth {
    getToken() {
        return SixMedConfig.apiKey;
    }
    logout() {
        window.location.hash = '/';
    }
}
export default new Auth();
